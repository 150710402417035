<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableUsers.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onCreate"
          >
            Add User
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tableUsers"
        hover
        responsive
        class="mt-2"
        :per-page="tableUsers.perPage"
        :current-page="tableUsers.currentPage"
        :items="tableProvider"
        :fields="tableUsers.fields"
        :sort-by.sync="tableUsers.sortBy"
        :sort-desc.sync="tableUsers.sortDesc"
        :sort-direction="tableUsers.sortDirection"
        :filter="tableUsers.filter"
        :filter-included-fields="tableUsers.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          <div class="text-center">
            {{ tableUsers.currentPage * tableUsers.perPage - tableUsers.perPage + (data.index + 1) }}
          </div>
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap text-center">
            <b-button
              size="sm"
              variant="primary"
              @click="onEdit(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableUsers.perPage"
              :options="tableUsers.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableUsers.currentPage"
            :total-rows="tableUsers.totalRows"
            :per-page="tableUsers.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-user-form"
      scrollable
      no-close-on-backdrop
      aria-hidden="false"
      no-enforce-focus
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="formUser"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="first name"
            vid="first_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="first_name">
                <strong>First Name</strong>
              </label>
              <b-input
                id="first_name"
                v-model="user.first_name"
                type="text"
                placeholder="enter first name"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="last name"
            vid="last_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="last_name">
                <strong>Last Name</strong>
              </label>
              <b-input
                id="last_name"
                v-model="user.last_name"
                type="text"
                placeholder="enter last name"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="email"
            vid="email"
            rules="required|email|max:100"
          >
            <b-form-group>
              <label for="email">
                <strong>Email</strong>
              </label>
              <b-input
                id="email"
                v-model="user.email"
                type="text"
                placeholder="enter email"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="passwordRule"
          >
            <b-form-group
              :description="state.editing ? 'Leave blank when not changing password' : null"
            >

              <label for="password_confirmation">
                <strong>Password Confirmation</strong>
              </label>
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid' : ''"
              >
                <b-input
                  id="password"
                  v-model="user.password"
                  placeholder="enter password here"
                  autocomplete="off"
                  :type="passwordType"
                  :state="errors.length > 0 ? false : null"
                  :disabled="state.busy"
                />
                <b-input-group-append>
                  <b-button
                    class="pl-2 pr-1 text-dark"
                    variant="warning"
                    style="border-radius: 0px 5px 5px 0px; background-color: var(--iq-body-color); border: 1px solid #ccc; border-left: 0px solid transparent;"
                    :disabled="state.busy"
                    @click="togglePassword"
                  >
                    <i
                      v-if="state.visible_password"
                      class="ri-eye-line h5"
                    />
                    <i
                      v-else
                      class="ri-eye-off-line h5"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password confirmation"
            vid="password_confirmation"
            :rules="passwordConfirmationRule"
          >
            <b-form-group
              :description="state.editing ? 'Leave blank when not changing password' : null"
            >
              <label for="password_confirmation">
                <strong>Password Confirmation</strong>
              </label>
              <b-input
                id="password_confirmation"
                v-model="user.password_confirmation"
                :type="passwordType"
                placeholder="enter password confirmation"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="role"
            vid="role"
            rules="required"
          >
            <b-form-group>
              <label for="role">
                <strong>Role</strong>
              </label>
              <b-form-select
                id="role"
                v-model="user.role"
                type="text"
                :options="list.roles"
                :state="errors.length > 0 ? false : null"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select Role --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="position"
            vid="position"
            rules="required_if:role,logistics-officer,senior-mechanic,logistics-manager,purchasing"
          >
            <b-form-group>
              <label for="position">
                <strong>Position</strong>
              </label>
              <b-form-select
                id="position"
                v-model="user.position"
                type="text"
                :options="list.positions"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy || user.role === 'admin'"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Position --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing && user.id !== 1"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="user.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              switch
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SAdminUser, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'VAdminUserAccounts',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'User Accounts'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false,
        visible_password: false
      },
      list: {
        positions: [],
        roles: [
          { text: 'Administrator', value: 'admin' },
          { text: 'Logistics Officer', value: 'logistics-officer' },
          { text: 'Senior Mechanic', value: 'senior-mechanic' },
          { text: 'Logistics Manager', value: 'logistics-manager' },
          { text: 'Purchasing', value: 'purchasing' }
        ]
      },
      user: {
        id: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        role: null,
        position: null,
        active: 0
      },
      tableUsers: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'email' },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'role' },
          { key: 'position_name' },
          { key: 'active' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit User' : 'Add User'
    },

    passwordRule () {
      return `${this.state.editing ? '' : 'required|'}min:8|confirmed:password_confirmation`
    },

    passwordConfirmationRule () {
      return `${this.state.editing ? '' : 'required'}`
    },

    passwordType () {
      return this.state.visible_password ? 'text' : 'password'
    }

  },

  watch: {
    'user.role' (role) {
      if (role === 'admin') {
        this.user.position = null
      }
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    togglePassword () {
      this.state.visible_password = !this.state.visible_password
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableUsers.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      })

      return await SAdminUser.get(filters).then(
        ({ data }) => {
          this.tableUsers.totalRows = data.total_rows
          return data.items
        }
      )
        .catch(() => {
          this.tableUsers.totalRows = 0
          return []
        })
        .finally(() => {
          this.tableUsers.busy = false
        })
    },

    async getOptions () {
      await SSharedList.getPositions().then(
        ({ data }) => {
          this.list.positions = data.map(
            ({ id, position_name }) => ({
              text: position_name,
              value: id
            })
          )
        }
      )
    },

    onCreate () {
      this.state.editing = false
      this.user.id = 0
      this.user.position = null
      this.user.email = ''
      this.user.password = ''
      this.user.password_confirmation = ''
      this.user.first_name = ''
      this.user.last_name = ''
      this.user.role = null
      this.user.active = 1
      this.$bvModal.show('modal-user-form')
    },

    onEdit (item) {
      this.state.editing = true
      this.user.id = item.id
      this.user.position = item.position_id
      this.user.email = item.email
      this.user.password = ''
      this.user.password_confirmation = ''
      this.user.first_name = item.first_name
      this.user.last_name = item.last_name
      this.user.role = item.role
      this.user.active = item.active
      this.$bvModal.show('modal-user-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formUser.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: this.state.editing ? 'Update User?' : 'Create User?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPut()
                }

                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPost () {
      return new Promise(
        resolve => {
          SAdminUser.post(this.user).then(
            ({ data }) => {
              this.$bvModal.hide('modal-user-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.tableUsers.refresh()
              })
            }
          ).catch(error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.formUser.setErrors(error.message)
              }
            }
          }).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    async onPut () {
      return new Promise(
        resolve => {
          SAdminUser.put(this.user).then(
            ({ data }) => {
              this.$bvModal.hide('modal-user-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                const row = _.find(this.$refs.tableUsers.localItems, {
                  id: data.user.id
                })
                row.position_id = data.user.position_id
                row.position_name = data.user.position_name
                row.email = data.user.email
                row.first_name = data.user.first_name
                row.last_name = data.user.last_name
                row.role = data.user.role
                row.active = data.user.active
                row.updated_at = data.user.updated_at
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formUser.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
